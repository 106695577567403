import { Typography, Button } from "antd";

export const JournalsExpertiseTable = () => {
  return (
    <div className="table-container" id="journals-expertise-table">
      <Typography.Title level={4}>
        Сведения о положительном заключении экспертизы проектной документации
      </Typography.Title>
      <Button type="primary" style={{ marginTop: "14px" }}>
        Выбрать
      </Button>
    </div>
  );
};
