import { Table, Button, Typography } from "antd";
import "./project-settings-individual.scss";
import EditIcon from "@/shared/assets/svg/icons/edit-icon.svg?react";

const settingsData = [
  {
    key: "1",
    object: "Корпус 1",
    deadlines: "Подключено",
    eojr: "Отключено",
  },
  {
    key: "2",
    object: 'Котельная ЖК "Ромашки"',
    deadlines: "Подключено",
    eojr: "Отключено",
  },
];

export const ProjectSettingsIndividual = () => {
  return (
    <div className="project-settings-individual table-container">
      <Typography.Title level={5} className="project-settings-title">
        Индивидуальные настройки в объектах
      </Typography.Title>
      <div className="settings-content">
        <Table
          columns={[
            {
              title: "Объект",
              dataIndex: "object",
              key: "object",
            },
            {
              title: "Дедлайны",
              dataIndex: "deadlines",
              key: "deadlines",
            },
            {
              title: "Электронный ОЖР",
              dataIndex: "eojr",
              key: "eojr",
              render: (text) => (
                <span style={{ color: "#888888", fontWeight: "300" }}>
                  {text}
                </span>
              ),
            },
          ]}
          dataSource={settingsData}
          pagination={false}
          bordered={false}
          className="settings-table"
        />
        <Button className="edit-button" shape="circle" icon={<EditIcon />} />
      </div>
    </div>
  );
};
