import { Tabs } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { routes } from "@/shared/lib/react-router";
import "./object-tabs.scss";

const getTabs = (projectId: string, objectId: string) => [
  {
    key: routes.objects.object(projectId, objectId),
    label: "Общая информация",
  },
  {
    key: routes.objects.users(projectId, objectId),
    label: "Участники",
  },
  {
    key: routes.objects.journals(projectId, objectId),
    label: "Журналы",
  },
  {
    key: routes.objects.settings(projectId, objectId),
    label: "Настройки",
  },
];

export const ObjectTabs = () => {
  const navigate = useNavigate();
  const { projectId, objectId } = useParams();
  const location = useLocation();
  const tabs = getTabs(projectId || "", objectId || "");

  const defaultActiveKey = tabs.reduce((bestMatch, tab) => {
    return location.pathname.startsWith(tab.key) &&
      tab.key.length > (bestMatch?.length || 0)
      ? tab.key
      : bestMatch;
  }, "");

  const onTabChange = (key: string) => {
    navigate(key);
  };
  return (
    <Tabs
      defaultActiveKey={defaultActiveKey}
      items={tabs}
      className="project-custom-tabs"
      onChange={onTabChange}
      tabBarStyle={{
        overflow: "hidden",
      }}
      tabBarGutter={32}
      style={{ marginBottom: "32px" }}
    />
  );
};
