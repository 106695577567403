import { createElement } from "react";
import { RouteObject } from "react-router-dom";
import { routes } from "@shared/lib/react-router";
import { ProjectDetail } from "./ui";

export const ProjectDetailRoute = (...rest: RouteObject[]): RouteObject => {
  return {
    path: `${routes.general.root()}`,
    element: createElement(ProjectDetail),
    children: [...rest],
  };
};
