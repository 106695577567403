import { Outlet } from "react-router-dom";
import { Typography } from "antd";
import "./project-objects-view.scss";
import { routes } from "@/shared/lib/react-router";
import { Breadcrumbs } from "@/features/breadcrumb";
import { ObjectTabs } from "@/features/object-tabs";

const { Title } = Typography;

const routesMock = [
  { path: "", label: "Проекты" },
  { path: routes.project.root({ projectId: "312" }), label: "Загородный дом" },
  { path: routes.general.objects(), label: "Объекты" },
];

export const ProjectObjectsView = () => {
  return (
    <div className="general-view-container">
      <Breadcrumbs routes={routesMock} />
      <Title level={3}>Жилой дом</Title>
      <ObjectTabs />
      <Outlet />
    </div>
  );
};
