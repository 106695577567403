import { useState, useEffect, useRef } from "react";
import "./project-related-tabs.scss";

interface IProjectRelatedTabs {
  tabs: string[];
  activeTab: string;
  setActiveTab: (tab: string) => void;
}

export const ProjectRelatedTabs = ({
  tabs,
  activeTab,
  setActiveTab,
}: IProjectRelatedTabs) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [indicatorStyle, setIndicatorStyle] = useState({
    width: "0px",
    transform: "translateX(0px)",
  });

  function getButtonWidth(tabs: string[], activeTab: string) {
    if (containerRef.current) {
      const activeIndex = tabs.indexOf(activeTab);
      const buttons = containerRef.current.querySelectorAll<HTMLButtonElement>(
        ".related-tab-button",
      );

      if (buttons[activeIndex]) {
        const button = buttons[activeIndex];
        const buttonOffsetWidth = button.offsetWidth;
        const buttonOffsetLeft = button.offsetLeft;
        setIndicatorStyle({
          width: `${buttonOffsetWidth}px`,
          transform: `translateX(${buttonOffsetLeft}px)`,
        });
      }
    }
  }

  useEffect(() => {
    getButtonWidth(tabs, activeTab);
  }, [tabs, activeTab]);

  return (
    <div style={{ display: "inline-block" }}>
      <div className="related-tabs-wrapper" ref={containerRef}>
        <div
          className="related-highlight"
          style={{
            width: indicatorStyle.width,
            transform: indicatorStyle.transform,
          }}
        />
        <div className="related-tabs">
          {tabs.map((tab) => (
            <button
              key={tab}
              type="button"
              className={`related-tab-button ${
                activeTab === tab ? "active" : ""
              }`}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};
