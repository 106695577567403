import { Typography, Button } from "antd";
import "./journals-commissioner-table.scss";

export const JournalsCommissionerTable = () => {
  return (
    <div className="table-container" id="journals-commissioner-table">
      <Typography.Title level={4}>
        Уполномоченный представитель технического заказчика
      </Typography.Title>
      <Button type="primary" style={{ marginTop: "14px" }}>
        Добавить сотрудника
      </Button>
    </div>
  );
};
