import { createElement } from "react";
import { RouteObject } from "react-router-dom";
import { GuestLayout } from "./ui/guest-layout";

export const createGuestLayout = (...rest: RouteObject[]): RouteObject => {
  return {
    element: createElement(GuestLayout),
    children: [...rest],
  };
};
