import { ReactNode } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form, Input, Modal, Typography } from "antd";
import { useForm } from "react-hook-form";
import { FormItem } from "react-hook-form-antd";
import { User } from "@entities/user";
import { projectTypesDto, projectContractsDto } from "@shared/api/project";
import { useDisclosure } from "@shared/lib/hooks";
// TODO: создать апи для организаций и фичу где тип инициализируем

export type RepresentativesType = {
  developerAssignee: User | undefined;
  customerAssignee: User | undefined;
};

export function CreateProjectWrapper({
  children,
}: {
  children: ({ onOpen }: { onOpen: () => void }) => ReactNode;
}) {
  const { onOpen, isOpen, onClose } = useDisclosure();
  // const [representatives, setRepresentatives] = useState<RepresentativesType>(
  //   {} as RepresentativesType,
  // );
  const methods = useForm<projectTypesDto.CreateProjectDto>({
    resolver: zodResolver(projectContractsDto.CreateProjectDtoSchema),
  });
  const handleSubmit = () => {
    console.log("submit project create");
    onClose();
  };
  return (
    <>
      {children({ onOpen })}
      <Modal
        title="Создание проекта"
        open={isOpen}
        onOk={methods.handleSubmit(handleSubmit)}
        onCancel={onClose}
        destroyOnClose
        okText="Сохранить"
        cancelText="Отмена"
      >
        <Form>
          <FormItem control={methods.control} name="description">
            <Input placeholder="Описание" />
          </FormItem>
          <FormItem control={methods.control} name="constructionAddress">
            <Input placeholder="Адрес" />
          </FormItem>
          <Typography.Title level={5}>
            Информация о ген. подрядчике
          </Typography.Title>
        </Form>
      </Modal>
    </>
  );
}
