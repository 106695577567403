import { Button, Input, Table, Typography } from "antd";
import { Link } from "react-router-dom";
import "./object-members-list.scss";
import BasketIcon from "@/shared/assets/svg/icons/basket-icon.svg?react";
import SearchIcon from "@/shared/assets/svg/icons/search-icon.svg?react";
import InfoIcon from "@/shared/assets/svg/icons/info-icon.svg?react";

const { Title, Text } = Typography;

const data = [
  {
    key: 1,
    organization: "ООО “Интэнт”",
    inn: "1234567890",
    kpp: "1234011001",
    role: "ОЖР №1: Застройщик, Лицо осуществляющее строительство\nОЖР №2: Застройщик, Лицо осуществляющее строительство",
  },
  {
    key: 2,
    organization: "ООО “Организация”",
    inn: "1234567890",
    kpp: "1234011001",
    role: "ОЖР №1: Лицо осуществляющее строительство",
  },
  {
    key: 3,
    organization: "ООО “Технические решения в строительстве”",
    inn: "1234567890",
    kpp: "1234011001",
    role: "ОЖР №1: Технический заказчик",
  },
  {
    key: 4,
    organization: "ООО “ПИК”",
    inn: "1234567890",
    kpp: "1234011001",
    role: "ОЖР №2: Иные организации",
  },
];

const columns = [
  {
    title: "Организация",
    dataIndex: "organization",
    key: "organization",
    render: (text: string) => (
      <div>
        <Link to="#">{text}</Link>
        <br />
        <Text type="secondary" style={{ fontSize: "12px" }}>
          ИНН: 1234567890
        </Text>
        <br />
        <Text type="secondary" style={{ fontSize: "12px" }}>
          КПП: 1234011001
        </Text>
      </div>
    ),
  },
  {
    title: "Роль",
    dataIndex: "role",
    key: "role",
    render: (text: string) => (
      <Text>
        {text.split("\n").map((line, index) => (
          <div key={index}>{line}</div>
        ))}
      </Text>
    ),
  },
  {
    title: "",
    key: "action",
    render: () => (
      <Button
        className="table-members-basket-container"
        icon={<BasketIcon width={16} height={16} />}
      ></Button>
    ),
  },
];

export const ObjectMembersList = () => {
  return (
    <div className="table-container">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Title level={4} style={{ marginBottom: "24px" }}>
          Участники строительства обьекта
        </Title>
        <div className="table-member-input-container">
          <Input suffix={<SearchIcon />} />
          <InfoIcon style={{ marginLeft: "8px" }} />
        </div>
      </div>

      <Table dataSource={data} columns={columns} pagination={false} />
    </div>
  );
};
