import { useEffect } from "react";
import { Card, Button, Row, Col, Descriptions, Avatar } from "antd";
import { useParams } from "react-router-dom";
import example_image from "@shared/assets/png/exemple-project-1.png";
import "./project-detail.scss";
// import { ProjectTabs } from '../project-tabs/project-tabs';

export const ProjectDetail = () => {
  // TODO Вынести в отдельный хук useParams
  const { projectId } = useParams();
  const projectData = " ";

  useEffect(() => {
    // TODO Здесь логика получения данных проекта с сервера
    // setProjectData(123);
  }, [projectId]);

  if (!projectData) {
    return <div>Загрузка...</div>;
  }

  return (
    <>
      <Card style={{ marginTop: 16 }}>
        <Row
          gutter={[16, 16]}
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Col
            xs={24}
            lg={6}
            style={{ display: "flex", justifyContent: "center" }}
          >
            {" "}
            <img
              src={example_image as string}
              alt="Загородный дом"
              style={{ borderRadius: "8px" }}
            />
          </Col>
          <Col xs={24} lg={18} style={{ paddingLeft: "16px" }}>
            <div className="project-info-container">
              <div className="project-title-container">
                <div className="project-title">Загородный дом</div>
                {/* <Button type="link">Редактировать</Button> */}
              </div>
              <div className="project-description">
                Загородный дом Дмитрия в Горной Шальдихе
              </div>
              <div className="description">
                <div className="description-item">
                  <span className="label">Адрес</span>
                  <span className="value">
                    Ленинградская область, Кировский район, дер. Горная
                    Шальдиха, ул. Хвойная уч. 40
                  </span>
                </div>
                <div className="description-item">
                  <span className="label">ID проекта</span>
                  <span className="value">
                    d6a5bae4-4b08-11ec-b6c8-d00d126464f0
                    <Button type="link">Скопировать</Button>
                  </span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Card>

      <Card
        size="default"
        title="Администратор проекта"
        style={{ marginTop: 16 }}
        // extra={<Button type="link">Добавить</Button>}
        className="project-detail-admin-card"
      >
        <Descriptions column={5} layout="vertical">
          <Descriptions.Item label="ФИО">
            <div style={{ display: "flex", alignItems: "center" }}>
              <Avatar
                src="https://via.placeholder.com/40"
                style={{ marginRight: "12px" }}
              />{" "}
              Харин Дмитрий
            </div>
          </Descriptions.Item>
          <Descriptions.Item label="Организация">ООО Интэнт</Descriptions.Item>
          <Descriptions.Item label="Должность">Дизайнер</Descriptions.Item>
          <Descriptions.Item label="Email">dima@builddocs.ru</Descriptions.Item>
          <Descriptions.Item label="Телефон">
            +7 (921) 334-4000
          </Descriptions.Item>
        </Descriptions>
      </Card>
    </>
  );
};
