import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { Splitter } from "antd";
import { Outlet } from "react-router-dom";
import { useModulesQuery } from "@entities/user";
import { Notifications, useModalStore } from "@features/notifications";
import { UserService } from "@shared/api/user";
import { Footer } from "./footer";
import { UserHeader } from "./header/header";

export const UserLayout = () => {
  const { isOpen } = useModalStore();
  const { data } = useModulesQuery();
  // const { mutate: loginMe } = useLoginMutation();
  const { data: oldUser } = useQuery({
    queryFn: UserService.oldService_UserCurrentQuery,
    queryKey: ["user", "old", "current"],
  });
  useEffect(() => {
    if (oldUser) {
      console.log("СМОТРИТЕ ЭТО ОТВЕТ ОТ СТАРОГО БИЛДДОКСА", oldUser.data);
      console.log("А ВОТ ПОЛНАЯ МОДЕЛЬКА ДАННЫХ АКСИОСА", oldUser);
      console.log("А ТУТ НОВЫЙ БИЛДОКС", data);
    }
  }, [oldUser]);
  return (
    // TODO:   из-за сплиттеров до правильного рендеринга (0-2  frame) страницы происходит флешскрин.
    // когда-нибудь нужно будет с этим разобраться
    //   v2: нужно разделить интерфейс, для мобильных устройств - использовать не сплиттеры, высчитывать брейкпоинты: useBreakpoint из antd
    <Splitter style={{ minHeight: "calc(100vh - 104px)" }}>
      <Splitter.Panel
        style={{ padding: "0px 0px" }}
        defaultSize="85%"
        min="70%"
        max="100%"
      >
        <UserHeader />
        <main
          style={{
            minHeight: "calc(100vh - 104px)",
            display: "flex",
            position: "relative",
          }}
        >
          <Outlet />
        </main>
        <Footer />
      </Splitter.Panel>
      {isOpen && (
        <Splitter.Panel min="15%" max="30%" resizable={isOpen}>
          <Notifications />
        </Splitter.Panel>
      )}
    </Splitter>
  );
};
