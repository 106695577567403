import { z } from "zod";
import { UserDtoSchema } from "@shared/api/user/contracts.ts";

export const OrganizationBaseSchema = z.object({
  inn: z.string(),
  kpp: z.string().optional(),
  phone: z.string().optional(),
  sro: z.string().nullable().optional(),
  sroDate: z.string().nullable().optional(),
  sroEngineering: z.string().nullable().optional(),
  sroEngineeringDate: z.string().nullable().optional(),
});

const PhysicalAddressSchema = z.object({
  street: z.string().optional(),
  city: z.string().optional(),
  state: z.string().optional(),
  postalCode: z.string().optional(),
  country: z.string().optional(),
});

export const OrganizationSchema = OrganizationBaseSchema.extend({
  name: z.string().optional(),
  ogrn: z.string().optional(),
  ogrnDate: z.string().optional(),
  sroIssuerInn: z.string().nullable().optional(),
  sroIssuerName: z.string().nullable().optional(),
  sroIssuerOgrn: z.string().nullable().optional(),
  sroEngineeringName: z.string().nullable().optional(),
  sroEngineeringOgrn: z.string().nullable().optional(),
  sroEngineeringInn: z.string().nullable().optional(),
  legalAddress: z.any().optional(),
  physicalAddress: PhysicalAddressSchema.optional(),
  createDateTime: z.string().optional(),
  apiToken: z.string().optional(),
});

export const CreateProjectDtoSchema = z.object({
  projectId: z.string().optional(),
  inn: z.string(),
  description: z.string({ message: "вставь в меня буквы" }),
  constructionAddress: z.string(),
  developer: OrganizationBaseSchema.optional(),
  customer: OrganizationBaseSchema.optional(),
  customerAssigneeEmploymentId: z.string().optional(),
  developerAssigneeEmploymentId: z.string().optional(),
  createDateTime: z.string().optional(),
});

export const CreateProjectResponseSchema = z.object({
  projectId: z.string().optional(),
  inn: z.string(),
  description: z.string(),
  constructionAddress: z.string(),
  developer: OrganizationSchema.optional(),
  customer: OrganizationSchema.optional(),
  developerAssignee: UserDtoSchema.optional(),
  customerAssignee: UserDtoSchema.optional(),
  createDateTime: z.string().optional(),
});
