import { Button } from "antd";
import "./journals-side-table.scss";
import GreenIcon from "@/shared/assets/svg/icons/green-dot-icon.svg?react";
import RedIcon from "@/shared/assets/svg/icons/red-dot-icon.svg?react";

const data = [
  {
    id: 1,
    title: "Информация об объекте",
    status: true,
  },
  {
    id: 2,
    title:
      "Сведения о застройщике, эксплуатирующей организации или региональном операторе",
    status: null,
  },
  {
    id: 3,
    title: "Уполномоченный представитель застройщика",
    status: null,
  },
  {
    id: 4,
    title: "Технический заказчик",
    status: null,
  },
  {
    id: 5,
    title: "Уполномоченный представитель технического заказчика",
    status: null,
  },
  {
    id: 6,
    title: "Разрешение на строительство",
    status: null,
  },
  {
    id: 7,
    title: "Лицо, осуществляющее подготовку проектной документации",
    status: null,
  },
  {
    id: 8,
    title: "Авторский надзор",
    status: null,
  },
  {
    id: 9,
    title:
      "Сведения о положительном заключении экспертизы проектной документации",
    status: null,
  },
  {
    id: 10,
    title: "Лицо, осуществляющее строительство",
    status: null,
  },
  {
    id: 11,
    title: "Уполномоченный представитель лица, осуществляющего строительство",
    status: null,
  },
  {
    id: 12,
    title:
      "Уполномоченный застройщика или тех заказчика по вопросам стройконтроля",
    status: null,
  },
  {
    id: 13,
    title:
      "Уполномоченный представитель лица, ответственного за эксплуатацию здания, сооружения, и (или) регионального оператора по вопросам строительного контроля",
    status: null,
  },
  {
    id: 14,
    title:
      "Уполномоченный представитель лица, осуществляющего строительство по вопросам строительного контроля",
    status: null,
  },
  {
    id: 15,
    title:
      "Другие лица, осуществляющие строительство, их уполномоченные представители",
    status: null,
  },
  {
    id: 16,
    title: "Общие сведения об объекте капитального строительства",
    status: null,
  },
];

export const JournalsSideTable = () => {
  return (
    <div className="table-container" id="journals-side-table">
      <ul className="info-section-list">
        {data.map((item) => (
          <li key={item.id} className="info-section-list-item">
            {item.status ? (
              <GreenIcon width={6} height={6} style={{ marginRight: "10px" }} />
            ) : (
              <RedIcon width={6} height={6} style={{ marginRight: "10px" }} />
            )}

            <div>{item.title}</div>
          </li>
        ))}
      </ul>
      <div className="separator" />
      <Button type="primary" style={{ marginTop: "16px" }}>
        Сохранить
      </Button>
      <div />
      <Button type="primary" style={{ marginTop: "16px" }}>
        Отправить на проверку
      </Button>
    </div>
  );
};
