import { Typography } from "antd";
import { Outlet } from "react-router-dom";
import "./project-general-view.scss";
import { ProjectTabs } from "@/features/project-tabs";

const { Title } = Typography;

export const ProjectGeneralView = () => {
  return (
    <div className="general-view-container">
      <Title level={3} style={{ marginBottom: "32px" }}>
        Загородный дом
      </Title>
      <ProjectTabs />
      <Outlet />
    </div>
  );
};
