import { Button, Typography } from "antd";
import { useState } from "react";
import { RepresentationMoodal } from "../representation-modal";

export const JournalsRepresentationTable = () => {
  const [isRepresentation, setIsRepresentation] = useState(false);
  const handleAdd = () => {
    setIsRepresentation((prev) => !prev);
  };
  return (
    <div className="table-container" id="journals-permission-table">
      <Typography.Title level={4}>
        Уполномоченный представитель застройщика
      </Typography.Title>
      <Button type="primary" style={{ marginTop: "14px" }} onClick={handleAdd}>
        Добавить сотрудника
      </Button>
      <RepresentationMoodal
        isRepresentation={isRepresentation}
        handleAdd={handleAdd}
      />
    </div>
  );
};
