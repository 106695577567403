import "./object-info.scss";
import { ObjectInfoPermission } from "../object-info-permission";
import { ObjectInfoOpinion } from "../object-info-opinion";
import { TableInfoType, TableInfo } from "@/features/table-info";

const tableMock: TableInfoType[] = [
  {
    id: 1,
    title: "Краткое наименование объекта",
    info: "Жилой дом",
  },
  {
    id: 2,
    title: "Вид строительства",
    info: "Строительство",
  },
  {
    id: 3,
    title: "Наименование объекта капитального строительства",
    info: "Жилой двухэтажный каркасный дом синего цвета с блестящей трубой снаружи",
  },
  {
    id: 4,
    title: "Краткие проектные характеристики",
    info: "Удовлетворительные",
  },
  {
    id: 5,
    title: "Описание этапа строительства, реконструкции",
    info: "Бесконечное строительство",
  },
  {
    id: 6,
    title: "Строительный адрес",
    info: "Ленинградская область, Кировский район, дер. Горная Шальдиха, ул. Хвойная уч. 40",
  },
  {
    id: 7,
    title: "Начало строительства",
    info: "11.11.2023",
  },
  {
    id: 8,
    title: "Окончание строительства",
    info: "11.11.2025",
  },
];

export const ObjectInfo = () => {
  return (
    <>
      <TableInfo
        tabs={tableMock}
        name="Информация об объекте строительства"
        isButton
        handleIsButton={() => {
          console.log("open");
        }}
        buttonName="Редактировать"
      />
      <ObjectInfoPermission />
      <ObjectInfoOpinion />
    </>
  );
};
