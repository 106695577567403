import { Select, Typography } from "antd";

export const JournalsTecCustomerTable = () => {
  return (
    <div className="table-container" id="journals-tec-customer-table">
      <Typography.Title level={4}>Технический заказчик</Typography.Title>
      <div
        style={{ display: "flex", flexDirection: "column", marginTop: "14px" }}
      >
        <div>Организация</div>
        <Select
          placeholder="Введите название или ИНН"
          style={{ width: "50%", marginTop: "4px" }}
        />
      </div>
    </div>
  );
};
