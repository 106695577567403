import { Button, Card, Flex } from "antd";
import Meta from "antd/es/card/Meta";
import { useNavigate } from "react-router-dom";
import { routes } from "@/shared/lib/react-router";
import example_image from "@shared/assets/png/exemple-project-1.png";
import LocalIcon from "@shared/assets/svg/icons/local-icon.svg?react";
import PenIcon from "@shared/assets/svg/icons/pen-icon.svg?react";

import "./project-list.scss";
import { ProjectDetails } from "@/features/project-details";
import { CreateProjectWrapper } from "@pages/projects/ui/create-project-wrapper/create-project-wrapper.tsx";

const projects = [
  {
    title: "Загородный дом",
    image: example_image,
    description: "Загородный дом Дмитрия в Горной Шальдихе",
    details: {
      tasks: 9,
      files: 24,
      folders: 4,
    },
    location:
      "Ленинградская область, Кировский район, дер. Горная Шальдиха, ул. Хвойная, 40",
    contractors: [
      'ООО "РСТИ НА ГЛУХАРСКОЙ (СПЕЦИАЛИЗИРОВАННЫЙ ЗАСТРОЙЩИК)"',
      'ООО "ПОДРЯДЧИК"',
    ],
    id: 1,
  },
  {
    title: "Корпус 2",
    image: "",
    description: "Загородный дом Дмитрия в Горной Шальдихе",
    details: {
      tasks: 9,
      files: 24,
      folders: 4,
    },
    location:
      "Ленинградская область, Кировский район, дер. Горная Шальдиха, ул. Хвойная, 40",
    contractors: [
      'ООО "РСТИ НА ГЛУХАРСКОЙ (СПЕЦИАЛИЗИРОВАННЫЙ ЗАСТРОЙЩИК)"',
      'ООО "ПОДРЯДЧИК"',
    ],
    id: 2,
  },
  {
    title: "Корпус 2",
    image: "",
    description: "Загородный дом Дмитрия в Горной Шальдихе",
    details: {
      tasks: 9,
      files: 24,
      folders: 4,
    },
    location:
      "Ленинградская область, Кировский район, дер. Горная Шальдиха, ул. Хвойная, 40",
    contractors: [
      'ООО "РСТИ НА ГЛУХАРСКОЙ (СПЕЦИАЛИЗИРОВАННЫЙ ЗАСТРОЙЩИК)"',
      'ООО "ПОДРЯДЧИК"',
    ],
    id: 2,
  },
  {
    title: "Корпус 2",
    image: "",
    description: "Загородный дом Дмитрия в Горной Шальдихе",
    details: {
      tasks: 9,
      files: 24,
      folders: 4,
    },
    location:
      "Ленинградская область, Кировский район, дер. Горная Шальдиха, ул. Хвойная, 40",
    contractors: [
      'ООО "РСТИ НА ГЛУХАРСКОЙ (СПЕЦИАЛИЗИРОВАННЫЙ ЗАСТРОЙЩИК)"',
      'ООО "ПОДРЯДЧИК"',
    ],
    id: 2,
  },
  {
    title: "Корпус 2",
    image: "",
    description: "Загородный дом Дмитрия в Горной Шальдихе",
    details: {
      tasks: 9,
      files: 24,
      folders: 4,
    },
    location:
      "Ленинградская область, Кировский район, дер. Горная Шальдиха, ул. Хвойная, 40",
    contractors: [
      'ООО "РСТИ НА ГЛУХАРСКОЙ (СПЕЦИАЛИЗИРОВАННЫЙ ЗАСТРОЙЩИК)"',
      'ООО "ПОДРЯДЧИК"',
    ],
    id: 2,
  },
  {
    title: "Корпус 2",
    image: "",
    description: "Загородный дом Дмитрия в Горной Шальдихе",
    details: {
      tasks: 9,
      files: 24,
      folders: 4,
    },
    location:
      "Ленинградская область, Кировский район, дер. Горная Шальдиха, ул. Хвойная, 40",
    contractors: [
      'ООО "РСТИ НА ГЛУХАРСКОЙ (СПЕЦИАЛИЗИРОВАННЫЙ ЗАСТРОЙЩИК)"',
      'ООО "ПОДРЯДЧИК"',
    ],
    id: 2,
  },
  {
    title: "Корпус 2",
    image: "",
    description: "Загородный дом Дмитрия в Горной Шальдихе",
    details: {
      tasks: 9,
      files: 24,
      folders: 4,
    },
    location:
      "Ленинградская область, Кировский район, дер. Горная Шальдиха, ул. Хвойная, 40",
    contractors: [
      'ООО "РСТИ НА ГЛУХАРСКОЙ (СПЕЦИАЛИЗИРОВАННЫЙ ЗАСТРОЙЩИК)"',
      'ООО "ПОДРЯДЧИК"',
    ],
    id: 2,
  },
  {
    title: "Корпус 2",
    image: "",
    description: "Загородный дом Дмитрия в Горной Шальдихе",
    details: {
      tasks: 9,
      files: 24,
      folders: 4,
    },
    location:
      "Ленинградская область, Кировский район, дер. Горная Шальдиха, ул. Хвойная, 40",
    contractors: [
      'ООО "РСТИ НА ГЛУХАРСКОЙ (СПЕЦИАЛИЗИРОВАННЫЙ ЗАСТРОЙЩИК)"',
      'ООО "ПОДРЯДЧИК"',
    ],
    id: 2,
  },
  {
    title: "Корпус 2",
    image: "",
    description: "Загородный дом Дмитрия в Горной Шальдихе",
    details: {
      tasks: 9,
      files: 24,
      folders: 4,
    },
    location:
      "Ленинградская область, Кировский район, дер. Горная Шальдиха, ул. Хвойная, 40",
    contractors: [
      'ООО "РСТИ НА ГЛУХАРСКОЙ (СПЕЦИАЛИЗИРОВАННЫЙ ЗАСТРОЙЩИК)"',
      'ООО "ПОДРЯДЧИК"',
    ],
    id: 2,
  },
  {
    title: "Корпус 2",
    image: "",
    description: "Загородный дом Дмитрия в Горной Шальдихе",
    details: {
      tasks: 9,
      files: 24,
      folders: 4,
    },
    location:
      "Ленинградская область, Кировский район, дер. Горная Шальдиха, ул. Хвойная, 40",
    contractors: [
      'ООО "РСТИ НА ГЛУХАРСКОЙ (СПЕЦИАЛИЗИРОВАННЫЙ ЗАСТРОЙЩИК)"',
      'ООО "ПОДРЯДЧИК"',
    ],
    id: 2,
  },
  {
    title: "Корпус 2",
    image: "",
    description: "Загородный дом Дмитрия в Горной Шальдихе",
    details: {
      tasks: 9,
      files: 24,
      folders: 4,
    },
    location:
      "Ленинградская область, Кировский район, дер. Горная Шальдиха, ул. Хвойная, 40",
    contractors: [
      'ООО "РСТИ НА ГЛУХАРСКОЙ (СПЕЦИАЛИЗИРОВАННЫЙ ЗАСТРОЙЩИК)"',
      'ООО "ПОДРЯДЧИК"',
    ],
    id: 2,
  },
];

export const ProjectList = () => {
  const navigate = useNavigate();
  const handleNavigate = (link: number) => {
    navigate(`${routes.general.root(String(link))}`);
  };
  const handleIconClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.stopPropagation();
  };
  return (
    <Flex
      component={"main"}
      className="project-screen"
      vertical
      justify="space-between"
    >
      <div className="project-grid">
        {projects.map((project) => (
          <Card
            key={project.id}
            cover={
              project.image ? (
                <div className="project-image-container">
                  <img src={project.image as string} alt={project.title} />
                  <div className="project-image-edit" onClick={handleIconClick}>
                    <PenIcon width={12} height={12} />
                  </div>
                </div>
              ) : (
                <div className="project-image-container">
                  <div className="image-placeholder" />
                  <div className="project-image-edit" onClick={handleIconClick}>
                    <PenIcon width={12} height={12} />
                  </div>
                </div>
              )
            }
            className="project-card"
            onClick={() => handleNavigate(project.id)}
          >
            <Meta
              title={project.title}
              // style={{
              //   marginTop: "16px",
              //   marginBottom: "16px",
              //   fontSize: "16px",
              //   color: "#000000",
              //   fontWeight: "400px",
              // }}
              className="project-meta-title"
            />
            <ProjectDetails
              tasks={project.details.tasks}
              files={project.details.files}
              folders={project.details.folders}
            />
            <Meta
              description={project.description}
              style={{
                marginTop: "16px",
                fontSize: "12px",
                color: "#888888",
                fontWeight: "400",
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <LocalIcon
                width={16}
                height={16}
                style={{ marginRight: "8px" }}
              />
              <div className="project-location">{project.location}</div>
            </div>

            {project.contractors.map((contractor) => (
              <div className="project-contractors" key={contractor}>
                <div>{contractor}</div>
              </div>
            ))}
          </Card>
        ))}
      </div>
      <div className="project-button-wrapper">
        <CreateProjectWrapper>
          {({ onOpen }) => (
            <Button
              type="primary"
              size="middle"
              className="project-main-button-create"
              onClick={onOpen}
            >
              Создать проект
            </Button>
          )}
        </CreateProjectWrapper>
      </div>
    </Flex>
  );
};
