import { useState } from "react";
import { Dropdown, Button, Menu } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import { ProjectRelatedTabs } from "@/features/project-related-tabs";
import { JournalsExpandableTable } from "../journals-expandable-table";
import { JournalsSubscribersTable } from "../journals-subscribers-table";
import "./journals-title.scss";
import { JournalsSideTable } from "../journals-side-table";
import { JournalsInfoTable } from "../journals-info-table";
import { JournalsIntelligenceTable } from "../journals-intelligence-table";
import { JournalsTecCustomerTable } from "../journals-tec-customer-table";
import { JournalsCommissionerTable } from "../journals-commissioner-table";
import { JournalsPermissionTable } from "../journals-permission-table";
import { JournalsDocumentationTable } from "../journals-documentation-table";
import { JournalsSupervisionTable } from "../journals-supervision-table";
import { JournalsExpertiseTable } from "../journals-expertise-table";
import { JournalsConstructionTable } from "../journals-construction-table";
import { JournalsRepresentativeTable } from "../journals-representative-table";
import { JournalsCustomerTable } from "../journals-customer-table";
import { JournalsOperationTable } from "../journals-operation-table";
import { JournalsOtherTable } from "../journals-other-table";
import { JournalsConstructionDetailsTable } from "../journals-construction-details-table";
import { JournalsRepresentationTable } from "../journals-representation-table/ui";

export const JournalsTitle = () => {
  const [activeTab, setActiveTab] = useState("Общие сведения");
  const tabs = ["Общие сведения", "Замечания", "Сведения о регистрации в ГСН"];
  const menu = (
    <Menu>
      <Menu.Item key="1" icon={<i className="icon-settings" />}>
        Настройки
      </Menu.Item>
      <Menu.Item key="2" icon={<i className="icon-history" />}>
        История
      </Menu.Item>
      <Menu.Item key="3" icon={<i className="icon-xml" />}>
        Скачать .xml
      </Menu.Item>
      <Menu.Item key="4" icon={<i className="icon-pdf" />}>
        Скачать .pdf
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "24px",
          marginBottom: "24px",
        }}
      >
        <ProjectRelatedTabs
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <Dropdown overlay={menu} trigger={["click"]}>
          <Button
            shape="circle"
            icon={<EllipsisOutlined />}
            style={{
              borderRadius: "10px",
            }}
          />
        </Dropdown>
      </div>

      <JournalsExpandableTable />
      <JournalsSubscribersTable />
      <div className="journals-columns-wrapper">
        <div className="journals-main-column-wrapper">
          <JournalsInfoTable />
          <JournalsIntelligenceTable />
          <JournalsRepresentationTable />
          <JournalsTecCustomerTable />
          <JournalsCommissionerTable />
          <JournalsPermissionTable />
          <JournalsDocumentationTable />
          <JournalsSupervisionTable />
          <JournalsExpertiseTable />
          <JournalsConstructionTable />
          <JournalsRepresentativeTable />
          <JournalsCustomerTable />
          <JournalsOperationTable />
          <JournalsOtherTable />
          <JournalsConstructionDetailsTable />
        </div>
        <div className="journals-side-column-wrapper">
          <JournalsSideTable />
        </div>
      </div>
    </>
  );
};
