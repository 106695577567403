import { Typography, Select, Button } from "antd";

export const JournalsSupervisionTable = () => {
  return (
    <div className="table-container" id="journals-supervision-table">
      <Typography.Title level={4}>Авторский надзор</Typography.Title>
      <div
        style={{ display: "flex", flexDirection: "column", marginTop: "14px" }}
      >
        <div>Организация</div>
        <Select
          placeholder="Введите название или ИНН"
          style={{ width: "50%", marginTop: "4px" }}
        />
      </div>
      <Button
        type="primary"
        style={{ marginTop: "24px", display: "inline-block" }}
      >
        Добавить организацию
      </Button>
      <div />
      <Button type="primary" style={{ marginTop: "24px" }} disabled>
        Добавить сотрудника
      </Button>
    </div>
  );
};
