import { Outlet } from "react-router-dom";
import { Typography } from "antd";
import "./project-journals.scss";
const { Title } = Typography;

export const ProjectJournalsView = () => {
  return (
    <div className="general-view-container">
      <Title level={3}>Загородный дом #1</Title>
      <Outlet />
    </div>
  );
};
