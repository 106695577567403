import { ObjectJournalsElement } from "../object-journals-element";

enum tableTagsMockEnum {
  count = "count",
  new = "new",
  closed = "closed",
  inwork = "inwork",
}

type tableMockType = {
  id: string;
  title: string;
  tags: { tagType: tableTagsMockEnum; title: string }[];
  date: {
    dateYear: string;
    dateTime: string;
  };
  description: string;
};

const tableMock: tableMockType[] = [
  {
    id: "1",
    title: "Общий журнал работ №1",
    tags: [
      {
        tagType: tableTagsMockEnum.new,
        title: "Новый",
      },
    ],
    date: {
      dateYear: "02.08.2024",
      dateTime: "16:13 МСК",
    },
    description:
      "Ленинградская область, Кировский район, дер. Горная Шальдиха, ул.Хвойная уч. 40",
  },
  {
    id: "2",
    title: "Общий журнал работ №2",
    tags: [
      {
        tagType: tableTagsMockEnum.count,
        title: "999",
      },
      {
        tagType: tableTagsMockEnum.inwork,
        title: "В работе",
      },
    ],
    date: {
      dateYear: "02.08.2024",
      dateTime: "16:13 МСК",
    },
    description:
      "Ленинградская область, Кировский район, дер. Горная Шальдиха, ул.Хвойная уч. 40",
  },
  {
    id: "3",
    title: "Общий журнал работ №3",
    tags: [
      {
        tagType: tableTagsMockEnum.closed,
        title: "Закрыт",
      },
    ],
    date: {
      dateYear: "02.08.2024",
      dateTime: "16:13 МСК",
    },
    description:
      "Ленинградская область, Кировский район, дер. Горная Шальдиха, ул.Хвойная уч. 40",
  },
];

export const ObjectJournalsList = () => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {tableMock.map((item) => {
        return <ObjectJournalsElement key={item.id} journalInfo={item} />;
      })}
    </div>
  );
};

export type { tableMockType };
export { tableTagsMockEnum };
