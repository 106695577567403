import { useState } from "react";
import "./project-related.scss";
import { ProjectRelatedContracts } from "../project-related-contracts/project-related-contracts";
import { ProjectRelatedJournals } from "../project-related-journals/project-related-journals";
import { ProjectRelatedTabs } from "@/features/project-related-tabs";

export const ProjectRelated = () => {
  const [activeTab, setActiveTab] = useState("Договоры");
  const tabs = ["Договоры", "Журналы"];
  return (
    <>
      <ProjectRelatedTabs
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      {activeTab === "Договоры" ? (
        <ProjectRelatedContracts />
      ) : (
        <ProjectRelatedJournals />
      )}
    </>
  );
};
