import { FC, useState } from "react";
import { Table } from "antd";
import VectorIcom from "@/shared/assets/svg/icons/vector-icon.svg?react";
import "./journals-subscribers-table.scss";

// interface DataType {
//   key: string;
//   name: string;
//   role: string;
//   position: string;
//   email: string;
//   phone: string;
// }


export const JournalsSubscribersTable: FC = () => {
  const [expanded, setExpanded] = useState(false);

  // const columns: ColumnsType<DataType> = [
  //   {
  //     title: "ФИО",
  //     dataIndex: "name",
  //     key: "name",
  //     render: (text) => <a>{text}</a>,
  //   },
  //   {
  //     title: "Роль",
  //     dataIndex: "role",
  //     key: "role",
  //   },
  //   {
  //     title: "Должность",
  //     dataIndex: "position",
  //     key: "position",
  //   },
  //   {
  //     title: "Email",
  //     dataIndex: "email",
  //     key: "email",
  //   },
  //   {
  //     title: "Телефон",
  //     dataIndex: "phone",
  //     key: "phone",
  //   },
  // ];

  return (
    <div
      className="table-container table-container-scroll-tab"
      id="journals-subscribers-table"
    >
      <div onClick={() => setExpanded(!expanded)} className="table-header">
        <span>
          <VectorIcom className={`expandable-icon ${expanded ? "open" : ""}`} />
          Список подписантов
        </span>
      </div>
      <div className={`table-body ${expanded ? "open" : "closed"}`}>
        <Table
          columns={[]}
          dataSource={[]}
          pagination={false}
          bordered={false}
          rowClassName={(_, index) =>
            index % 2 === 0 ? "table-row-light" : "table-row-dark"
          }
        />
      </div>
    </div>
  );
};
