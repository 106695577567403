import { Typography, Button, Select, Input } from "antd";
import "./journals-other-table.scss";

export const JournalsOtherTable = () => {
  return (
    <div className="table-container" id="journals-other-table">
      <Typography.Title level={4}>
        Другие лица, осуществляющие строительство, их уполномоченные
        представители
      </Typography.Title>
      <div
        style={{ display: "flex", flexDirection: "column", marginTop: "14px" }}
      >
        <div>Организация</div>
        <Select
          placeholder="Введите название или ИНН"
          style={{ width: "50%", marginTop: "4px" }}
        />
      </div>
      <div
        style={{ display: "flex", flexDirection: "column", marginTop: "24px" }}
      >
        <div>Выполняемые работы</div>
        <Input style={{ marginTop: "4px" }} />
      </div>
      <Button
        type="primary"
        style={{ marginTop: "24px", marginBottom: "24px" }}
      >
        Добавить сотрудника
      </Button>
      <div className="separator" />
      <Button type="primary" style={{ marginTop: "24px" }}>
        Добавить организацию
      </Button>
    </div>
  );
};
