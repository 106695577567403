import { SettingsCardBlock } from "@/features/settings-card-block/ui/settings-card-block";
import "./object-settings.scss";

const cardsIntegrationMock = [
  {
    id: 1,
    title: "Дедлайны",
    description:
      "Позволяет настраивать срок устранения и реакции на события. Применяется только для выбранного объекта",
    author: "Харин Дмитрий",
    date: "02.08.2024 16:13 МСК",
    isActive: true,
  },
  {
    id: 2,
    description:
      "Отображает настройки указанные в проекте Изменение настроек происходит в проекте",
    title: "EXON",
    author: "Харин Дмитрий",
    date: "02.08.2024 16:13 МСК",
  },
];

export const ObjectSettings = () => {
  return (
    <>
      <SettingsCardBlock
        blockTitle={"Дополнения "}
        cards={cardsIntegrationMock}
      />
    </>
  );
};
