import { Typography, Select, Button } from "antd";

export const JournalsDocumentationTable = () => {
  return (
    <div className="table-container" id="journals-documentation-table">
      <Typography.Title level={4}>
        Лицо, осуществляющее подготовку проектной документации
      </Typography.Title>
      <div
        style={{ display: "flex", flexDirection: "column", marginTop: "14px" }}
      >
        <div>Организация</div>
        <Select
          placeholder="Введите название или ИНН"
          style={{ width: "50%", marginTop: "4px" }}
        />
      </div>
      <Button type="primary" style={{ marginTop: "24px" }}>
        Добавить организацию
      </Button>
    </div>
  );
};
