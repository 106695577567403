import { Table, Typography } from "antd";
import { Link } from "react-router-dom";
import "./object-info-permission.scss";

const { Title } = Typography;

const permissionsData = [
  {
    key: 1,
    number: "55/11-123",
    issueDate: "11.11.2023",
    issuingAuthority: "Комитет по комитетам строительных разрешений",
    expiryDate: "11.18.2024",
    extendedUntil: "05.04.2025",
  },
  {
    key: 2,
    number: "55/11-177",
    issueDate: "31.12.2023",
    issuingAuthority: "Комитет по комитетам строительных разрешений",
    expiryDate: "11.18.2024",
    extendedUntil: "05.04.2025",
  },
];

const columns = [
  {
    title: "№ разрешения",
    dataIndex: "number",
    key: "number",
    render: (text: string) => <Link to="#">{text}</Link>,
  },
  {
    title: "Дата выдачи",
    dataIndex: "issueDate",
    key: "issueDate",
  },
  {
    title: "Наименование органа, выдавшего разрешение",
    dataIndex: "issuingAuthority",
    key: "issuingAuthority",
  },
  {
    title: "Срок действия",
    dataIndex: "expiryDate",
    key: "expiryDate",
  },
  {
    title: "Продлено до",
    dataIndex: "extendedUntil",
    key: "extendedUntil",
  },
];

export const ObjectInfoPermission = () => {
  return (
    <div className="table-container">
      <Title level={5}>Разрешения на строительство</Title>
      <Table
        dataSource={permissionsData}
        columns={columns}
        pagination={false}
        className="custom-table"
      />
    </div>
  );
};
