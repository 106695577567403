import { createElement } from "react";
import { RouteObject } from "react-router-dom";
import { routes } from "@shared/lib/react-router";
import { ObjectMembers } from "./ui";

export const ObjectMembersRoute = (...rest: RouteObject[]): RouteObject => {
  return {
    path: `${routes.objects.users()}`,
    element: createElement(ObjectMembers),
    children: [...rest],
  };
};
