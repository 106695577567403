import { SettingsCardBlock } from "@/features/settings-card-block/ui/settings-card-block";
import { ProjectSettingsIndividual } from "../project-settings-individual";
import "./project-settings.scss";

const cardsIntegrationMock = [
  {
    id: 1,
    title: "Шина данных",
    description: "",
    author: "Харин Дмитрий",
    date: "02.08.2024 16:13 МСК",
    isActive: true,
  },
  {
    id: 2,
    description: "",
    title: "EXON",
    author: "Харин Дмитрий",
    date: "02.08.2024 16:13 МСК",
  },
  {
    id: 3,
    description: "",
    title: "Интеграция с ГСН",
    author: "Харин Дмитрий",
    date: "02.08.2024 16:13 МСК",
  },
];

const cardsEditionalMock = [
  {
    id: 1,
    title: "Дедлайны",
    description:
      "Позволяет настраивать срок устранения и реакции на события. Применяется ко всем объектам, если нет индивидуальной настройки объекта",
    author: "Харин Дмитрий",
    date: "02.08.2024 16:13 МСК",
    isActive: true,
  },
  {
    id: 2,
    description: "Включает возможность изменять даты акта АОСР вручную",
    title: "Изменение даты АОСР",
    author: "Харин Дмитрий",
    date: "02.08.2024 16:13 МСК",
  },
  {
    id: 3,
    description: "Электронный Общий Журнал Работ",
    title: "ЭОЖР",
    author: "Харин Дмитрий",
    date: "02.08.2024 16:13 МСК",
  },
];

export const ProjectSettings = () => {
  return (
    <>
      <SettingsCardBlock
        blockTitle={"Интеграции"}
        cards={cardsIntegrationMock}
        onAddClick={true}
      />
      <SettingsCardBlock
        blockTitle={"Дополнения"}
        cards={cardsEditionalMock}
        onAddClick={false}
      />
      <ProjectSettingsIndividual />
    </>
  );
};
