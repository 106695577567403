import { Outlet, useParams } from "react-router-dom";
import { ProjectList } from "./project-list";
import { Empty } from "./projectempty";

const isEmpty = false;
const ProjectsPage = () => {
  // TODO Вынести в отдельный хук useParams
  const { projectId } = useParams();
  if (projectId) {
    return <Outlet />;
  }
  return isEmpty ? <Empty /> : <ProjectList />;
};

export default ProjectsPage;
