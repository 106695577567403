import { ReactNode } from "react";
import { App, ConfigProvider } from "antd";
import { theme } from "@shared/ui";

type AntdConfigAppProviderProps = {
  children: ReactNode;
};

export function AntdGlobalProvider(props: AntdConfigAppProviderProps) {
  const { children } = props;
  return (
    <ConfigProvider {...theme}>
      <App>{children}</App>
    </ConfigProvider>
  );
}
