import "./arrow-icon.scss";

export const ArrowIcon = ({ isActive }: { isActive: boolean }) => {
  return (
    <div className="arrow-icon-container">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="7"
        viewBox="0 0 12 7"
        fill="none"
        style={{
          transform: isActive ? "rotate(180deg)" : "rotate(0deg)",
          transition: "transform 0.2s ease",
        }}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.99931 4.82979L1.52206 0.352539L0.726563 1.14803L5.99931 6.42078L11.2721 1.14803L10.4766 0.352539L5.99931 4.82979Z"
          fill="#333333"
        />
      </svg>
    </div>
  );
};
