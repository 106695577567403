import { Link } from "react-router-dom";
import { Dropdown, Menu } from "antd";
import TrashIcon from "@/shared/assets/svg/icons/basket-icon.svg?react";
import CopyIcon from "@/shared/assets/svg/icons/copy-icon.svg?react";
import MoreOneIcon from "@/shared/assets/svg/icons/more-one-icon.svg?react";
import { tableMockType } from "../object-journals-list/object-journals-list";

import "./object-journals-element.scss";

interface IObjectJournalsElement {
  journalInfo: tableMockType;
}

export const ObjectJournalsElement = ({
  journalInfo,
}: IObjectJournalsElement) => {
  const handleCopy = () => {
    console.log("Скопировать действие выполнено");
  };

  const handleDelete = () => {
    console.log("Удалить действие выполнено");
  };

  const menu = (
    <Menu>
      <Menu.Item key="copy" icon={<CopyIcon />} onClick={handleCopy}>
        Скопировать
      </Menu.Item>
      <Menu.Item key="delete" icon={<TrashIcon />} onClick={handleDelete}>
        Удалить
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="table-container journal-container">
      <div className="journal-header-container">
        <div className="journal-info-container">
          <Link to="#" style={{ marginRight: "16px", fontSize: "16px" }}>
            {journalInfo.title}
          </Link>
          <div className="journal-tags-container">
            {journalInfo.tags.map((item) => {
              return (
                <div
                  className={`journal-tag ${item.tagType}`}
                  key={item.tagType}
                >
                  {item.title}
                </div>
              );
            })}
          </div>
        </div>
        <div className="journal-date-container">
          <div className="journal-date-info">{journalInfo.date.dateYear}</div>
          <div className="journal-date-time">{journalInfo.date.dateTime}</div>
          <Dropdown overlay={menu} trigger={["click"]}>
            <MoreOneIcon style={{ cursor: "pointer" }} width={24} height={24} />
          </Dropdown>
        </div>
      </div>
      <div className="journal-description">{journalInfo.description}</div>
    </div>
  );
};
