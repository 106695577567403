import { Button, Typography } from "antd";
import { ObjectJournalsList } from "../object-journals-list";

const { Title } = Typography;

export const ObjectJournals = () => {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginBottom: "24px",
        }}
      >
        <Title level={4}>Список журналов обьекта</Title>
        <Button type="default">Создать ОЖР</Button>
      </div>
      <ObjectJournalsList />
    </>
  );
};
