export class UserQueriesTags {
  static root() {
    return ["user"];
  }

  static modules() {
    return [...UserQueriesTags.root(), "modules"] as const;
  }

  static login() {
    return [...UserQueriesTags.root(), "login"] as const;
  }
}
