import { ConfigProviderProps } from "antd/es/config-provider";
import { OverrideToken } from "antd/es/theme/interface";

export const theme: ConfigProviderProps = {
  wave: { disabled: true },
  theme: {
    token: {
      fontFamily: "Roboto, sans-serif",
    },
    components: {
      Table: {
        borderColor: "rgb(255,255,255)",
        headerColor: "rgb(102,102,102)",
        fontWeightStrong: 400,
        headerBg: "rgb(255,255,255)",
        headerFilterHoverBg: "rgba(255,255,255,0.06)",
        headerSortHoverBg: "rgb(255,255,255)",
        rowHoverBg: "#EAEEF8",
        rowSelectedHoverBg: "rgb(255,0,4)",
        bodySortBg: "rgb(255,255,255)",
        headerSortActiveBg: "rgb(255,255,255)",
        headerSplitColor: "rgb(255,255,255)",
        padding: 12,
      },
      Typography: {
        fontSizeHeading3: 24,
        colorText: "#333333",
        fontFamilyCode: "'Roboto",
        fontWeightStrong: 500,
        colorTextHeading: "#333333",
        colorLink: "rgb(235,47,150)",
        colorLinkActive: "rgba(0,52,173,0.95)",
        colorLinkHover: "rgba(105,178,255,0.9)",
      },
      Button: {
        colorLinkHover: "rgb(65,105,225)",
        colorPrimary: "rgb(65,105,225)",
        borderRadius: 10,
        defaultBorderColor: "rgb(234,238,248)",
        // defaultActiveBorderColor: "rgb(234,238,248)",
        // defaultActiveColor: "rgb(234,238,248)",
        defaultHoverBorderColor: "rgb(234,238,248)",
        // defaultHoverColor: "rgb(0,0,0)",
        // defaultHoverBg: "rgb(234,238,248)",
      },
      Tabs: {
        inkBarColor: "rgb(51,51,51)",
        itemHoverColor: "rgb(0,0,0)",
        itemSelectedColor: "rgb(51,51,51)",
        horizontalMargin: "0 0 0 0",
        verticalItemMargin: "16px 0 16px 0",
        colorBorder: "rgb(255,0,0)",
        colorBorderSecondary: "#BCBFC0",
      },
      Switch: {
        colorPrimary: "rgb(0,52,173)",
        colorPrimaryBorder: "rgb(0,52,173)",
        colorPrimaryHover: "rgb(0,78,173)",
        colorTextQuaternary: "rgb(188,191,192)",
        handleSize: 16,
        trackHeight: 20,
      },
      Card: {
        boxShadowCard: "none",
        boxShadowTertiary: "none",
      },
      Input: {
        borderRadius: 10,
      },
      Select: {
        borderRadius: 10,
      },
      Radio: {
        colorPrimary: "rgb(188,191,192)",
        dotSize: 8,
        colorText: "rgb(51,51,51)",
      },
    },
  },
} as ConfigProviderProps & { components?: OverrideToken };
