import { Button, Input, Modal, Typography } from "antd";
import "./representation-modal.scss";
import { Link } from "react-router-dom";
import { CloseOutlined } from "@ant-design/icons";

interface IRepresentationMoodal {
  isRepresentation: boolean;
  handleAdd: () => void;
}

export const RepresentationMoodal = ({
  isRepresentation,
  handleAdd,
}: IRepresentationMoodal) => {
  return (
    <>
      <Modal
        open={isRepresentation}
        onCancel={handleAdd}
        footer={null}
        centered
        width={700}
      >
        <Typography.Title level={4} style={{ marginBottom: "24px" }}>
          Добавление Уполномоченного
        </Typography.Title>
        <div style={{ marginBottom: "24px", color: "#333333" }}>
          Поиск сотрудника по адресу электронной почты
        </div>

        <div className="email-input-container">
          <div className="email-inpit-lable">
            <div className="email-input-title">Электронная почта</div>
            <Input placeholder="почта" style={{ minWidth: "320px" }} />
          </div>
          <Button type="primary">Найти</Button>
        </div>
        <div className="confirmation-credentials">
          <div className="confirmation-credentials-inputs-container">
            <div className="confirmation-credentials-label">
              <div className="confirmation-credentials-title">
                Наименование документа
              </div>
              <Input placeholder="Приказ" />
            </div>
            <div className="confirmation-credentials-label">
              <div className="confirmation-credentials-title">
                Номер документа
              </div>
              <Input placeholder="№ГГ-12" />
            </div>
            <div className="confirmation-credentials-label">
              <div className="confirmation-credentials-title">Дата выдачи </div>
              <Input placeholder="12.12.2012" type="date" />
            </div>
          </div>
        </div>
        <div className="document-selector-container">
          <div
            className="document-selector-button"
            style={{ marginBottom: "16px" }}
          >
            <div className="document-selector-button-title">
              Прикрепите документ
            </div>
            <Button>Загрузить</Button>
          </div>
          <div className="document-selector-button">
            <Link to={"#"} className="document-selector-button-title link">
              название документа.pdf (4kB)
            </Link>
            <CloseOutlined
              onClick={() => {
                console.log(123);
              }}
              className="close-icon"
            />
          </div>
        </div>
        <div className="organization-credentials">
          <div className="organization-credentials-label">
            <div className="organization-credentials-title">
              Организация, выдавшая документ (необ.)
            </div>
            <Input placeholder="" />
          </div>
          <div className="organization-credentials-label">
            <div className="organization-credentials-title">
              Описание (необ.)
            </div>
            <Input placeholder="" />
          </div>
        </div>
        <div
          style={{
            width: "100%",
            height: "1px",
            backgroundColor: "#DDDDDD",
            marginBottom: "16px",
            marginTop: "24px",
          }}
        />
        <Button type="primary" disabled>
          Добавить
        </Button>
      </Modal>
    </>
  );
};
