import { Table, Typography } from "antd";
import "./project-related-journals.scss";
import { Link } from "react-router-dom";

const { Title } = Typography;

const dataSource = [
  {
    key: "1",
    journal: "Общий журнал работ №1",
    name: "Загородный дом",
    address:
      "Ленинградская область, Кировский район, дер. Горная Шальдиха, ул. Хвойная уч. 40",
  },
  {
    key: "2",
    journal: "Общий журнал работ №2",
    name: "Корпус 1",
    address:
      "Ленинградская область, Кировский район, дер. Горная Шальдиха, ул. Хвойная уч. 40",
  },
  {
    key: "3",
    journal: "Общий журнал работ №3",
    name: "Котельная ЖК “Ромашки”",
    address:
      "Ленинградская область, Кировский район, дер. Горная Шальдиха, ул. Хвойная уч. 40",
  },
];

const columns = [
  {
    title: "Журнал",
    dataIndex: "journal",
    key: "journal",
    render: (text: string) => <Link to={text}>{text}</Link>, // Замените на нужный путь
  },
  {
    title: "Наименование объекта",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Адрес",
    dataIndex: "address",
    key: "address",
  },
];

export const ProjectRelatedJournals = () => {
  return (
    <>
      <Title
        style={{
          fontSize: "20px",
          color: "#333333",
          fontWeight: "500",
          marginTop: "24px",
          marginBottom: "24px",
        }}
      >
        Журналы
      </Title>
      <div className="journal-table-container">
        <Title
          level={4}
          style={{
            fontSize: "16px",
            color: "#333333",
            fontWeight: "500",
            paddingLeft: "16px",
          }}
        >
          Общий журнал работ
        </Title>
        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          bordered={false}
          className="custom-table"
        />
      </div>
    </>
  );
};
