import { create } from "zustand";
import { persist } from "zustand/middleware";

type ModalState = {
  isOpen: boolean;
  setIsOpen: () => void;
  setIsClose: () => void;
};

export const useModalStore = create(
  persist<ModalState>(
    (set) => ({
      isOpen: false,
      setIsOpen: () => set({ isOpen: true }),
      setIsClose: () => set({ isOpen: false }),
    }),
    {
      name: "notifications-modal-storage",
    },
  ),
);
