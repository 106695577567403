import ActionIcon from "@shared/assets/svg/icons/action-icon.svg?react";
import DocumIcon from "@shared/assets/svg/icons/docum-icon.svg?react";
import OrjIcon from "@shared/assets/svg/icons/ojr-icon.svg?react";

import "./project-details.scss";

interface IProjectDetails {
  tasks?: number;
  files?: number;
  folders?: number;
}

export const ProjectDetails = ({ tasks, files, folders }: IProjectDetails) => {
  return (
    <div className="project-details">
      {tasks ? (
        <div className="detail-item" data-tooltip="Акты ожидающие действия">
          <ActionIcon width={24} height={24} />{" "}
          <div className="detail-item-count">{tasks}</div>
        </div>
      ) : null}
      {files ? (
        <div className="detail-item" data-tooltip="Штампы на подписание">
          <DocumIcon width={24} height={24} />{" "}
          <div className="detail-item-count">{files}</div>
        </div>
      ) : null}
      {folders ? (
        <div className="detail-item" data-tooltip="Журналы действия">
          <OrjIcon width={24} height={24} />{" "}
          <div className="detail-item-count">{folders}</div>
        </div>
      ) : null}
    </div>
  );
};
