import { FC, useState } from "react";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import VectorIcom from "@/shared/assets/svg/icons/vector-icon.svg?react";
import "./journals-expandable-table.scss";

interface DataType {
  key: string;
  name: string;
  role: string;
  position: string;
  email: string;
  phone: string;
}

const data: DataType[] = [
  {
    key: "1",
    name: "Харин Дмитрий",
    role: "Администратор",
    position: "Дизайнер",
    email: "dima@builddocs.ru",
    phone: "+7 (921) 334-4000",
  },
  {
    key: "2",
    name: "Харин Дмитрий ⚽",
    role: "Заполнение данных",
    position: "Дизайнер",
    email: "dima@builddocs.ru",
    phone: "+7 (921) 334-4000",
  },
  {
    key: "3",
    name: "Харин Дмитрий",
    role: "Проверка данных",
    position: "Дизайнер",
    email: "dima@builddocs.ru",
    phone: "+7 (921) 334-4000",
  },
  {
    key: "4",
    name: "Харин Дмитрий",
    role: "Отправка в ГСН",
    position: "Дизайнер",
    email: "dima@builddocs.ru",
    phone: "+7 (921) 334-4000",
  },
];

export const JournalsExpandableTable: FC = () => {
  const [expanded, setExpanded] = useState(false);

  const columns: ColumnsType<DataType> = [
    {
      title: "ФИО",
      dataIndex: "name",
      key: "name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Роль",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Должность",
      dataIndex: "position",
      key: "position",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Телефон",
      dataIndex: "phone",
      key: "phone",
    },
  ];

  return (
    <div
      className="table-container table-container-scroll-tab"
      id="journals-documentation-table"
    >
      <div onClick={() => setExpanded(!expanded)} className="table-header">
        <span>
          <VectorIcom className={`expandable-icon ${expanded ? "open" : ""}`} />
          Ответственные лица
        </span>
      </div>
      <div className={`table-body ${expanded ? "open" : "closed"}`}>
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          bordered={false}
          rowClassName={(_, index) =>
            index % 2 === 0 ? "table-row-light" : "table-row-dark"
          }
        />
      </div>
    </div>
  );
};
