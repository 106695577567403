import { FC } from "react";
import { Radio } from "antd";
import { Controller, Control } from "react-hook-form";

interface IObjectFormRadio {
  name: string;
  control: Control<any>;
  radioOptions: string[];
  label?: string;
}

export const ObjectFormRadio: FC<IObjectFormRadio> = ({
  name,
  control,
  label,
  radioOptions,
}) => (
  <div className="form-section-label">
    {label ? <div className="form-label-input primary">{label}</div> : null}
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Radio.Group {...field}>
          {radioOptions.map((item) => {
            return (
              <Radio value={item} key={item}>
                {item}
              </Radio>
            );
          })}
        </Radio.Group>
      )}
    />
  </div>
);
