import { z } from "zod";

export const UserModulesDtoSchema = z.object({
  modules: z.string().array(),
});

export const UserDtoSchema = z.object({
  userHistoryId: z.string(),
  userId: z.string(),
  userFirstName: z.string(),
  userSurname: z.string(),
  userMiddleName: z.string(),
  userMobilePhone: z.string(),
  userEmail: z.string(),
  userDateOfBirth: z.string(),
  hasLoggedIn: z.boolean(),
  userAvatarId: z.string(),
  isBlock: z.boolean(),
});

export const LoginUserDtoSchema = z.object({
  login: z.string().min(0).max(32),
  password: z.string().min(8).max(32),
});

export const RegisterUserDtoSchema = z.object({
  login: z.string().min(0).max(32),
  password: z.string().min(8).max(32),
});
