import { Button } from "antd";

import "./object-form-load-file.scss";

export const ObjectFormLoadFile = () => {
  return (
    <div className="upload-section">
      <div className="upload-item">
        <div>
          <p className="title">Прикрепите документ</p>
          <p className="description">.pdf до 10MB</p>
        </div>
        <Button type="default" style={{ width: "50%" }}>
          Загрузить
        </Button>
      </div>

      <div className="upload-item">
        <div>
          <p className="title">Прикрепите подпись</p>
          <p className="description">.sig, .p7s, .sign, .sgn</p>
        </div>
        <Button style={{ width: "50%" }}>Загрузить</Button>
      </div>
    </div>
  );
};
