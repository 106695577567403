import { Table, Avatar, Typography } from "antd";
import { Link } from "react-router-dom";
import "./project-history.scss";

const historyData = [
  {
    key: "1",
    employee: "Дима Харин",
    position: "Дизайнер",
    organization: 'ООО "Организация"',
    event: "Назначение администратора",
    date: "02.08.2024",
    time: "16:13 МСК",
  },
  {
    key: "2",
    employee: "Дима Харин",
    position: "Дизайнер",
    organization: 'ООО "Интэнт"',
    event: "Создание проекта",
    date: "02.08.2024",
    time: "16:13 МСК",
  },
];

const columns = [
  {
    title: <span style={{ color: "#666666" }}>Сотрудник</span>,
    dataIndex: "employee",
    key: "employee",
    render: (text: string) => (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Avatar
          src="https://via.placeholder.com/40"
          alt="avatar"
          style={{ marginRight: "8px" }}
        />
        <Link to="#" style={{ color: "#1d4ed8" }}>
          {text}
        </Link>
      </div>
    ),
  },
  {
    title: <span style={{ color: "#666666" }}>Должность</span>,
    dataIndex: "position",
    key: "position",
  },
  {
    title: <span style={{ color: "#666666" }}>Организация</span>,
    dataIndex: "organization",
    key: "organization",
  },
  {
    title: <span style={{ color: "#666666" }}>Событие</span>,
    dataIndex: "event",
    key: "event",
  },
  {
    title: <span style={{ color: "#666666" }}>Дата, Время</span>,
    dataIndex: "date",
    key: "date",
    render: (text: string, record: { time: string }) => (
      <div style={{ display: "flex", flexDirection: "row" }}>
        <span style={{ color: "#333333", marginRight: "4px" }}>{text}</span>{" "}
        <br />
        <span style={{ color: "#888888" }}>{record.time}</span>
      </div>
    ),
  },
];

export const ProjectHistory = () => {
  return (
    <div
      style={{
        backgroundColor: "#ffffff",
        padding: "24px",
        borderRadius: "16px",
      }}
    >
      <Typography.Title level={4} style={{ marginBottom: "16px" }}>
        История проекта
      </Typography.Title>
      <Table
        columns={columns}
        dataSource={historyData}
        pagination={false}
        bordered={false}
        className="history-table"
      />
    </div>
  );
};
