import { Table, Typography } from "antd";
import { Link } from "react-router-dom";
import "./object-info-opinion.scss";

const { Title } = Typography;

const opinionsData = [
  {
    key: 1,
    number: "001-001",
    issueDate: "11.11.2023",
    issuingAuthority:
      "Комитет по положительным заключениям экспертизы проектной документации",
    expiryDate: "11.18.2024",
    extendedUntil: "05.04.2025",
  },
  {
    key: 2,
    number: "001-002",
    issueDate: "31.12.2023",
    issuingAuthority:
      "Комитет по положительным заключениям экспертизы проектной документации",
    expiryDate: "11.18.2024",
    extendedUntil: "05.04.2025",
  },
];

const columns = [
  {
    title: "№ заключения",
    dataIndex: "number",
    key: "number",
    render: (text: string) => <Link to="#">{text}</Link>,
  },
  {
    title: "Дата выдачи",
    dataIndex: "issueDate",
    key: "issueDate",
  },
  {
    title: "Наименование органа, выдавшего заключение",
    dataIndex: "issuingAuthority",
    key: "issuingAuthority",
    render: (text: string) => (
      <div style={{ maxWidth: "490px", minWidth: "490px" }}>{text}</div>
    ),
  },
  {
    title: "Срок действия",
    dataIndex: "expiryDate",
    key: "expiryDate",
  },
  {
    title: "Продлено до",
    dataIndex: "extendedUntil",
    key: "extendedUntil",
  },
];

export const ObjectInfoOpinion = () => {
  return (
    <div className="table-container">
      <Title level={5}>
        Сведения о положительном заключении экспертизы проектной документации
      </Title>
      <Table
        dataSource={opinionsData}
        columns={columns}
        pagination={false}
        className="custom-table"
      />
    </div>
  );
};
