import { Button, Typography } from "antd";

export const JournalsPermissionTable = () => {
  return (
    <div className="table-container" id="journals-permission-table">
      <Typography.Title level={4}>Разрешение на строительство</Typography.Title>
      <Button type="primary" style={{ marginTop: "14px" }}>
        Выбрать
      </Button>
    </div>
  );
};
