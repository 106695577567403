import { AxiosRequestConfig, AxiosResponse } from "axios";
import { baseApi, oldBaseApi } from "@shared/api";
import {
  LoginUserDto,
  RegisterUserDto,
  UserModulesDto,
  UserResponse,
} from "./types";

export class UserService {
  static loginUserMutation(data: {
    LoginUserDto: LoginUserDto;
  }): Promise<AxiosResponse<UserResponse>> {
    return baseApi.post("login", data);
  }

  static registerUserMutation(data: {
    RegisterUserDto: RegisterUserDto;
  }): Promise<AxiosResponse<UserResponse>> {
    return baseApi.post("register", data);
  }

  static userModulesQuery(
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<UserModulesDto>> {
    return baseApi.get("api/v1/users/modules", config || {});
  }

  static oldService_UserCurrentQuery(
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<UserModulesDto>> {
    return oldBaseApi.get("api/users/current", config || {});
  }
}
