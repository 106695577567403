import { Typography, Button } from "antd";

export const JournalsCustomerTable = () => {
  return (
    <div className="table-container" id="journals-customer-table">
      <Typography.Title level={4}>
        Уполномоченный застройщика или тех заказчика по вопросам стройконтроля
      </Typography.Title>
      <Button type="primary" style={{ marginTop: "14px" }}>
        Добавить сотрудника
      </Button>
    </div>
  );
};
