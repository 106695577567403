import {
  Col,
  Dropdown,
  Menu,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Typography,
} from "antd";
import { Fragment, useState } from "react";
import { tableIntelligenceMock, tableIntelligenceMockType } from "./tableMock";
import TrashIcon from "@/shared/assets/svg/icons/basket-icon.svg?react";
import CopyIcon from "@/shared/assets/svg/icons/copy-icon.svg?react";
import MoreOneIcon from "@/shared/assets/svg/icons/more-one-icon.svg?react";

const { Title } = Typography;

enum orgEnum {
  builder = "builder",
  exploitationOrg = "exploitationOrg",
  regionalOrg = "regionalOrg",
}

export const JournalsIntelligenceTable = () => {
  const [typeOrg, setTypeOrg] = useState<orgEnum>(orgEnum.builder);
  const [selectedOrg, setSelectedOrg] =
    useState<tableIntelligenceMockType | null>(null);

  const handleChangeType = (e: RadioChangeEvent) => {
    setTypeOrg(e.target.value);
  };

  const handleChangeOrg = (key: number) => {
    const foundOrg = tableIntelligenceMock.find((item) => item.key === key);
    setSelectedOrg(foundOrg || null);
  };

  const handleEdit = () => {
    console.log("edit");
  };

  const handleDelete = () => {
    setSelectedOrg(null);
    setTypeOrg(orgEnum.builder);
  };

  const menu = (
    <Menu>
      <Menu.Item key="copy" icon={<CopyIcon />} onClick={handleEdit}>
        Скопировать
      </Menu.Item>
      <Menu.Item key="delete" icon={<TrashIcon />} onClick={handleDelete}>
        Удалить
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="table-container">
      <Title level={4} style={{ marginBottom: "24px" }}>
        Сведения о застройщике, эксплуатирующей организации или региональном
        операторе
      </Title>
      {selectedOrg ? (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div style={{ marginBottom: "16px", fontSize: "16px" }}>
              ООО "{selectedOrg.name}"
            </div>
            <Dropdown overlay={menu} trigger={["click"]}>
              <MoreOneIcon
                style={{ cursor: "pointer" }}
                width={24}
                height={24}
              />
            </Dropdown>
          </div>
          <Row gutter={[16, 16]}>
            {selectedOrg.table.map(({ id, title, info }) => (
              <Fragment key={id}>
                <Col span={8} style={{ color: "#888888" }}>
                  {title}
                </Col>
                <Col span={16} className="table-info-content">
                  {info}
                </Col>
              </Fragment>
            ))}
          </Row>
        </>
      ) : (
        <>
          <div>Тип организации</div>
          <Radio.Group
            onChange={handleChangeType}
            value={typeOrg}
            style={{ marginTop: "8px" }}
          >
            <Radio value={orgEnum.builder}>Застройщик</Radio>
            <Radio value={orgEnum.exploitationOrg}>
              Эксплуатирующая организация
            </Radio>
            <Radio value={orgEnum.regionalOrg}>Региональный оператор</Radio>
          </Radio.Group>
          <div style={{ marginTop: "24px" }}>
            <div style={{ marginBottom: "4px" }}>Организация</div>
            <Select
              placeholder="Введите название или ИНН"
              onChange={handleChangeOrg}
            >
              {tableIntelligenceMock.map((item) => {
                return (
                  <Select.Option key={item.key} value={item.key}>
                    <div>OOO "{item.name}"</div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        color: "#888888",
                      }}
                    >
                      <div>ИНН "{item.table[1].info}"</div>
                      <div>КПП "{item.table[2].info}"</div>
                      <div>ОГРН "{item.table[3].info}"</div>
                    </div>
                  </Select.Option>
                );
              })}
            </Select>
          </div>
        </>
      )}
    </div>
  );
};
