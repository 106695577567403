import axios from "axios";
// import { env } from "@shared/config.ts";

const $token =
  "eyJhbGciOiJSUzI1NiJ9.eyJ1c2VySWQiOiJiNWE2MDdjZC0zMjNiLTExZWItYmQzZC1kMDBkMTI2NDY0ZjAiLCJlbXBsb3ltZW50SWQiOiJiMDhiOTUxOC1jMzhkLTExZWItOTRkMi1kMDBkMTRkYjZmOTEiLCJpbm4iOiI3ODEwNDk1MTg1LTc4MDEwMTAwMSIsInJvbGVOYW1lIjoiYWNjb3VudDp3b3JrZXIiLCJyb2xlSWQiOiJXT1JLRVIiLCJlbWFpbCI6IjlAbWFpbC5ydSIsInBob25lIjoiKzc5MDgyNTUzMjA0IiwiaWF0IjoxNzMyODc4ODA4LCJleHAiOjE3MzI4ODI0MDh9.gGq0KRZIz9tHM8rE6zl8H7QLOpUwiXwKFs0wl7azh_sdQtbrYtSUYSfkC_3ubTrNpsqYBUynB2ONO3utzDz8SJEUwAtv_68JGwrUzTT_Rwelsf3QiZUVYX3mYN0CyyyNfScjMmoR06_WCgPySsRA-nK8ghcuv6hRRaT9vER-Jlus2OPZAXdZS0W6SvHHZmgAlMMIBPwI796rWFjikR8ZzrQ3Z_5VLdnG52W_OumTcjQC-Y0cg1oKNLPYIW5swh4NiXf9r7RQ-u7e0GgBF2okQEpwjfHJpk2_BLe778Zyc5YPb8lZsxUZWj73oQNRQBWQYYLa2CwG_RIRu6MZHGWzUA";

export const baseApi = axios.create({
  baseURL: "/userservice",
});

baseApi.defaults.headers.common = {
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Methods": "GET, POST, OPTIONS",
  "Content-Type": "application/json",
  Authorization: `Bearer ${$token}`,
};

const $oldToken =
  "eyJhbGciOiJSUzI1NiJ9.eyJ1c2VySWQiOiJiNWE2MDdjZC0zMjNiLTExZWItYmQzZC1kMDBkMTI2NDY0ZjAiLCJlbXBsb3ltZW50SWQiOiJiMDhiOTUxOC1jMzhkLTExZWItOTRkMi1kMDBkMTRkYjZmOTEiLCJpbm4iOiI3ODEwNDk1MTg1LTc4MDEwMTAwMSIsInJvbGVOYW1lIjoiYWNjb3VudDp3b3JrZXIiLCJyb2xlSWQiOiJXT1JLRVIiLCJlbWFpbCI6IjlAbWFpbC5ydSIsInBob25lIjoiKzc5MDgyNTUzMjA0IiwiaWF0IjoxNzMzMjI0MzE0fQ.PWYlomFupztUhOs7IGiaHSL4S_BlZiHiI23V1XrVSCtTaTp8qm23dEMbpvuRPviJ3rez2mgV3Ne4FQkHyoVd09rlNCWBNFv6E4s7OlJgCsdZzBjg90xDoJvE_IQ5I2DfDeLQGlrebvb2qR59VfkDj0lSjkE3T7eRJQ_SRQ-edz6BrbWgE6W1pYGx74qOhGfm0AFUzG0X0YpVXSNKP1pYrMlZnh-I5KK5779SI6nRbSWp6aLfh0lg9_ylIfnJvrVtIPlVv9_XIQvNPXc5ML2TCyemNOvqbPdelD8Qo0y8EusL3ORrbqO7hUVa6HXDN_fK9iUiP9GlbrUVzLMrLsx40Q";

export const oldBaseApi = axios.create({
  baseURL: "/old-builddocs-api",
});

oldBaseApi.defaults.headers.common = {
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Methods": "GET, POST, OPTIONS",
  "Content-Type": "application/json",
  Authorization: `Bearer ${$oldToken}`,
};
